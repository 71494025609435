import React from "react"

const BlogCard = ({ path, index, title, teaser, date }) => {
  return (
    <>
      <div
        className="card lift lift-lg shadow-lift mb-5"
        key={index}
      >
        <a className="card-body my-auto" href={path}>
          <h2 className="mt-auto mb-1">{title}</h2>
          <p className="mb-0 text-muted">{teaser}</p>
        </a>

        <a className="card-meta" href={path}>
          <p className="h6 text-uppercase text-muted mb-0 ms-auto">
            <time dateTime={date}>{date}</time>
          </p>
        </a>
      </div>
    </>
  )
}

export default BlogCard
