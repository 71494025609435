import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BlogCard from "../components/blog-card"

const Blog = ({ data }) => {
  // selectable tags on blog page
  // only a subset, to avoid visual overload
  const tags = [
    ["Data Protection", "/tags/data-protection/"],
    ["Automotive", "/tags/automotive/"],
    ["Computer Vision", "/tags/computer-vision/"],
    ["Machine Learning", "/tags/machine-learning/"],
    ["Mobile Mapping", "/tags/mobile-mapping/"],
  ]

  const languages = [
    ["English", "/tags/english/"],
    ["Deutsch", "/tags/deutsch/"],
  ]

  return (
    <Layout>
      <SEO
        title="Our Blog about Data Privacy for Images and Video - Celantur"
        description="We write about data anonymization, mobile mapping, autonomous driving and computer vision."
      />
      <section className="container pt-5 my-5 text-justify">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-10 text-center mb-3">
            <h1 className="display-3 font-weight-bold">Celantur Blog</h1>
            <p className="font-size-lg text-muted">
              Explore our blog for insights on technology,
              artificial intelligence, and privacy.
            </p>
            <div>
              {tags.map((tag, index) => {
                return (
                  <Link
                    to={tag[1]}
                    role="button"
                    className="btn btn-pill btn-sm btn-dark-soft mr-2 mb-2"
                    key={index}
                  >
                    {tag[0]}
                  </Link>
                )
              })}
            </div>
            <div>
              {languages.map((language, index) => {
                return (
                  <Link
                    to={language[1]}
                    role="button"
                    className="btn btn-pill btn-sm btn-secondary-soft mr-2 mb-2"
                    key={index}
                  >
                    {language[0]}
                  </Link>
                )
              })}
            </div>
          </div>
        </div>
      </section>

      <section className="container my-5">
        <div>
          {data.allMarkdownRemark.edges
            .filter((post) => post.node.frontmatter.title.length > 0)
            .map(({ node: post }, index) => {
              return (
                <BlogCard
                  title={post.frontmatter.title}
                  path={post.frontmatter.path}
                  teaser={post.frontmatter.teaser}
                  date={post.frontmatter.date}
                  index={index}
                />
              )
            })}
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query BlogQuery {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { glob: "**/src/markdown-pages/blog/*.md" } }
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 1000
    ) {
      edges {
        node {
          frontmatter {
            path
            title
            date(formatString: "DD MMMM YYYY")
            author
            teaser
            tags
          }
        }
      }
    }
  }
`

export default Blog
